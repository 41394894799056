import React, { useState } from "react";
import { Page } from "../../../Components/Page.tsx";
import { Button, Select, Skeleton, Stack, Title } from "@mantine/core";
import { sendCommand } from "../../../commandTypes.ts";
import { gamePauseClock, troetHorn } from "../../../openapi/api-client.ts";
import { IconPlayerPause, IconVolume } from "@tabler/icons-react";
import { useCompetitionList } from "../../../hooks/useCompetitionList.ts";
import { TeamName } from "../../../Components/TeamName.tsx";
import { TextSkeleton } from "../../../Components/TextSkeleton.tsx";
import { useMutation } from "@tanstack/react-query";
import { activityItems } from "../Ledwall/activityItems.ts";
import { useCurrentGame } from "../../../hooks/useCurrentGame.ts";

export const Command: React.FC = () => {
  const competitionList = useCompetitionList();
  const [selectedCompetitionDisplay, selectCompetitionDisplay] = useState<
    string | null
  >(null);
  const [selectedCompetitionGameControl, selectCompetitionGameControl] =
    useState<string | undefined>(undefined);
  const [selectedActivityItemId, selectActivityItemId] = useState<
    string | null
  >(null);

  const currentGame = useCurrentGame(selectedCompetitionGameControl);

  const pauseGameMutation = useMutation({
    mutationFn: gamePauseClock,
  });

  return (
    <Page>
      <Stack spacing="xl">
        <Stack spacing="md">
          <Title order={2}>Ledwall</Title>
          <Button.Group>
            <Button
              size="xl"
              w="50%"
              color="lime"
              onClick={async () => {
                await sendCommand({
                  command: "ledwall.enable",
                });
              }}
            >
              einschalten
            </Button>
            <Button
              size="xl"
              w="50%"
              color="red"
              onClick={async () => {
                await sendCommand({
                  command: "ledwall.disable",
                });
              }}
            >
              ausschalten
            </Button>
          </Button.Group>
          <Select
            data={activityItems.map((a) => ({
              label: a.label,
              value: a.id,
            }))}
            onChange={(v) => selectActivityItemId(v)}
            value={selectedActivityItemId}
            label="Aktionsfläche"
          />
          <Button.Group>
            <Button
              size="xl"
              w="65%"
              onClick={async () => {
                if (!selectedActivityItemId) {
                  throw new Error("activity item id not set");
                }

                sendCommand({
                  command: "ledwall.activityItem.select",
                  activityItemId: selectedActivityItemId,
                });
              }}
            >
              Aktionsfläche
            </Button>
            <Button
              size="xl"
              w="35%"
              color="red"
              onClick={async () => {
                sendCommand({
                  command: "ledwall.activityItem.disable",
                });
              }}
            >
              Aus
            </Button>
          </Button.Group>
        </Stack>
        <Stack spacing="md">
          <Title order={2}>Anzeigen</Title>
          {competitionList.isSuccess ? (
            <Select
              data={competitionList.data.competitions.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              label="Turnier"
              clearable
              value={selectedCompetitionDisplay}
              onChange={(value) => selectCompetitionDisplay(value)}
              description="Stream-Anzeigen, Zeitnehmer, Kommentator, Regie, Ledwalls"
            />
          ) : (
            <Skeleton height={61} />
          )}
          <Button
            size="xl"
            w="100%"
            onClick={async () => {
              await sendCommand({
                command: "competition",
                competitionId: selectedCompetitionDisplay ?? "",
              });
              selectCompetitionDisplay(null);
              selectCompetitionGameControl(
                selectedCompetitionDisplay ?? undefined,
              );
            }}
            disabled={!selectedCompetitionDisplay}
          >
            Turnier umschalten
          </Button>
        </Stack>
        <Stack spacing="md">
          <Title order={2}>Spielsteuerung</Title>
          {competitionList.isSuccess ? (
            <Select
              data={competitionList.data.competitions.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              label="Turnier"
              clearable
              value={selectedCompetitionGameControl}
              onChange={(value) =>
                selectCompetitionGameControl(value ?? undefined)
              }
              description={
                <>
                  {currentGame.isSuccess &&
                  currentGame.data.actualTeams.homeTeamId ? (
                    <TeamName
                      teamId={currentGame.data.actualTeams.homeTeamId}
                    />
                  ) : (
                    <TextSkeleton>Heimmannschaft</TextSkeleton>
                  )}
                  {" vs. "}
                  {currentGame.isSuccess &&
                  currentGame.data.actualTeams.guestTeamId ? (
                    <TeamName
                      teamId={currentGame.data.actualTeams.guestTeamId}
                    />
                  ) : (
                    <TextSkeleton>Gastmannschaft</TextSkeleton>
                  )}
                </>
              }
            />
          ) : (
            <Skeleton height={61} />
          )}
          <Button.Group>
            <Button
              size="xl"
              w="65%"
              color="red"
              leftIcon={<IconPlayerPause />}
              disabled={
                !currentGame.isSuccess || !currentGame.data?.clockRunning
              }
              loading={pauseGameMutation.isPending}
              onClick={async () => {
                if (!currentGame.isSuccess) {
                  throw new Error("game not loaded");
                }
                pauseGameMutation.mutate({ id: currentGame.data.id });
                troetHorn({});
              }}
            >
              Anhalten&nbsp;
              <IconVolume />
            </Button>
            <Button
              size="xl"
              w="35%"
              leftIcon={<IconVolume />}
              color="yellow"
              onClick={async () => {
                await troetHorn({});
              }}
            >
              Tröt
            </Button>
          </Button.Group>
        </Stack>
      </Stack>
    </Page>
  );
};
